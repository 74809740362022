<template>
<section class="login-form">
    <h1 class="form-header uppercase">
        {{ $t('FORM.CREATE_YOUR_ACCOUNT') }}
    </h1>
    <form class="flex-column user-profile-form" novalidate v-on:submit.prevent="onSubmit">
        <UsernameInput
            id="username"
            v-model="username.value"
            :label="$t('FORM.USERNAME')"
            v-on:update:invalid="username.invalid = $event"
        />
        <template v-if="isUserEmailSignup()">
            <PasswordInput
                v-model="password.value"
                :label="$t('FORM.PASSWORD_SET')"
                v-on:update:invalid="password.invalid = $event"
            />
        </template>
        <div class="field">
            <FormErrorToolTip v-if="dob.invalid && dobErrText" :error-text="dobErrText" />
            <label for="dateOfBirth" class="label">{{ $t('FORM.DOB') }}</label>
            <input
                id="dateOfBirth" v-model="dob.value" type="date"
                :max="new Date().toISOString().split('T')[0]"
                name="dateOfBirth" required class="input uppercase dob-field"
                :class="{invalid: dob.invalid}"
                v-on:blur="validateDateOfBirth()"
                v-on:keydown="clearTypingTimer(typingTimer)"
                v-on:keyup="startTypingTimer('dateOfBirth')"
            >
        </div>
        <ButtonElement
            type="submit" class="submit-btn medium" :disabled="!canSubmit"
            v-on:click="createUserAcct"
            v-on:keydown.enter="createUserAcct"
        >
            {{ $t('FORM.CONTINUE') }}
        </ButtonElement>
    </form>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { JBGWeb } from '$types/JBGWeb'
import { useCurrentUser, User, UserApi } from '$services/user'
import { JBGApi } from '$services/api'
import ButtonElement from '$components/ButtonElement.vue'
import PasswordInput from '$components/input/fields/PasswordInput.vue'
import UsernameInput from '$components/input/fields/UsernameInput.vue'
import FormErrorToolTip from './FormErrorToolTip.vue'

export default defineComponent({
    components: {
        ButtonElement,
        FormErrorToolTip,
        PasswordInput,
        UsernameInput
    },

    props: {
        email: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            // always start with the same avatar by default.
            // users can update this later in their user account settings menu.
            selectedAvatar: 'avatar0',
            showPassword: false,

            username: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            password: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            dob: { value: '', invalid: false } as JBGWeb.UserFormFieldData,

            typingTimer: <ReturnType<typeof setTimeout> | null> null,

            currentUser: useCurrentUser()
        }
    },

    computed: {
        canSubmit() {
            return (
                !this.username.invalid && this.username.value.length > 0
                && !this.password.invalid && this.password.value.length > 0
                && !this.dob.invalid && this.dob.value.length > 0
            )
        },

        dobErrText(): string | null {
            // do not show error if username or password fields have error
            if (this.username.invalid || this.password.invalid) return null
            switch (this.dob.invalidationReason) {
            case 'dobBlank':
                return this.$t('FORM.ERRORS.DOB_BLANK')
            case 'dobFuture':
                return this.$t('FORM.ERRORS.DOB_FUTURE')
            case 'dobPast':
                return this.$t('FORM.ERRORS.DOB_TOO_OLD')
            default:
                return null
            }
        }
    },

    watch: {
        showPassword() {
            const eyeballIcon = document.getElementById('eyeball-icon')
            if (!eyeballIcon) return
            if (!this.showPassword) {
                eyeballIcon.classList.remove('fa-eye')
                eyeballIcon.classList.add('fa-regular', 'fa-eye-slash')
            }

            if (this.showPassword) {
                eyeballIcon.classList.remove('fa-eye-slash')
                eyeballIcon.classList.add('fa-regular', 'fa-eye')
            }
        }
    },

    methods: {
        onSubmit() {
            return false
        },

        onSelectAvatar(avatarIndex: string) {
            this.selectedAvatar = avatarIndex
        },

        createUserAcct() {
            if (this.currentUser.getStatus() === User.Status.CreatingBySSO) {
                // if a user got here using Google SSO
                void this.updateUserProfile()
            } else {
                // if a user got here typing in their email
                void this.emailSignUp()
            }
        },

        async emailSignUp() {
            try {
                // eslint-disable-next-line max-len
                await this.$user.emailSignup(this.email, this.password.value, this.password.value, this.username.value, this.dob.value)
                // route to verification screen
                void this.$router.push({
                    path: '/user/verify'
                })
            } catch (error) {
                if (error && error instanceof JBGApi.Error) {
                    console.error('saw a JBG error', error.code)
                    // httpStatus == 400 errors
                    switch (error.code) {
                    case 2001: // 2001 - email or pw not set
                        this.password.invalidationReason = 'passwordBlank'
                        if (!this.password.invalid) return
                        this.password.invalid = true
                        break
                    case 2002: // 2002 - passwords don't match
                        this.password.invalidationReason = 'passwordsNotMatch'
                        if (!this.password.invalid) return
                        this.password.invalid = true
                        break
                    default: // error.code: 1000
                        console.error('invalid request:', error)
                    }
                } else {
                    console.error(error)
                    this.$toast.add({
                        id: 'emailsignup',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            }
        },

        async updateUserProfile() {
            // wait a beat to make sure we catch any last minute validation checks
            await new Promise((resolve) => { setTimeout(resolve, 200) })

            if (this.canSubmit) {
                const u = <UserApi.UpdateRequest>{
                    username: this.username.value,
                    avatar: this.selectedAvatar, // TODO: avatar string validation?
                    dob: this.dob.value
                }
                try {
                    await this.$user.update(u)
                    const redirect = sessionStorage.getItem('redirect')
                    if (redirect !== null) {
                        window.location.href = redirect
                    } else {
                        void this.$router.push({ path: '/play' })
                    }
                } catch (err) {
                    if (err && err instanceof JBGApi.Error) {
                        console.error('error updating user profile: ', err)
                    } else {
                        this.$toast.add({
                            id: 'userupdate',
                            type: 'error',
                            text: this.$t('GENERAL.ERROR')
                        })
                    }
                }
            }
        },

        validateDateOfBirth() {
            if (!this.dob.value) return

            // dob is blank
            if (this.dob.value.length === 0) {
                this.dob.invalid = true
                this.dob.invalidationReason = 'dobBlank'
            }
            // dob is in the future
            if (new Date(this.dob.value) > new Date()) {
                this.dob.invalid = true
                this.dob.invalidationReason = 'dobFuture'
            }
            // dob is too far in the past
            if (new Date(this.dob.value) < new Date('1901')) {
                this.dob.invalid = true
                this.dob.invalidationReason = 'dobPast'
            }
        },

        clearTypingTimer(timer: ReturnType<typeof setTimeout> | null) {
            if (!timer) return
            clearTimeout(timer)
        },

        startTypingTimer(fieldName: string) {
            this.clearTypingTimer(this.typingTimer)
            const DONE_TYPING_INTERVAL = 1500
            switch (fieldName) {
            case 'dateOfBirth':
            case 'dob':
                this.dob.invalid = false
                this.typingTimer = setTimeout(this.validateDateOfBirth, DONE_TYPING_INTERVAL)
                break
            default:
                console.warn('no field name provided.')
                break
            }
        },

        isUserEmailSignup() {
            return this.currentUser.getStatus() === User.Status.CreatingByEmail
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.form-header {
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;

    @include mq-xsmall {
        font-size: 26px;
        font-weight: 800;
        line-height: 26px;
    }
}

.submit-btn {
    height: 54px;
    margin-top: 12px;
    font-size: 16px;
}

.uppercase {
    text-transform: uppercase;
}

.user-profile-form {
    align-items: stretch;
    text-align: left;

    .field {
        margin-top: 16px;
    }

    .label {
        color: var(--neutral-50);
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        text-transform: uppercase;
    }

    .input {
        color: var(--neutral-300);
        color-scheme: dark;
        font-size: 18px;
        font-weight: 400;
        height: 56px;
        line-height: 18px;
        margin: 8px 0 20px 0;
        padding: 16px;

        &:active, &:focus {
            outline: none;
        }

        &.invalid {
            border-bottom: 2px solid var(--red-300);
        }

        &.dob-field {
            font-size: 16px;
            line-height: 16px;

            // TODO: add spacing around forward slashes in date placeholder
        }
    }

    .password-field {
        margin-bottom: 0px;
    }

    .password-toggle-icon {
        cursor: pointer;
        align-self: end;
        position: relative;
        bottom: 36px;
        right: 16px;
    }
}
</style>
